@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

html,
body {
  height: 100%;
  margin: 0;
}

* {
  margin: 0;
  font-family: var(--font-openSans);
}

.tnc-container > p {
  margin-bottom: 20px;
}

.tnc-container > h3 {
  margin-bottom: 4px;
  @apply text-heading-h6;
}

.tnc-container > ol {
  list-style: decimal;
  padding-left: 24px;

  margin: 4px 4px 16px;
}

.tnc-container li {
  padding-left: 4px;
  margin-bottom: 2px;
}

.tnc-container > ol > ol {
  list-style: lower-alpha;
  padding-left: 20px;
  margin-bottom: 4px;
}
.tnc-container > ol > ol > ol {
  list-style: lower-roman;
  padding-left: 16px;
  margin-bottom: 4px;
}

.upper-alpha {
  list-style: upper-alpha !important;
  padding-left: 34px !important;
}
.lower-alpha {
  list-style: lower-alpha !important;
  padding-left: 40px !important;
}

.decimal {
  list-style: decimal !important;
  padding-left: 36px !important;
}

.lower-roman {
  list-style: lower-roman;
  padding-left: 48px;
  margin-bottom: 4px;
}
